import axios from 'axios'

// const apiUrl = 'https://go-teens-api.onrender.com'
const apiUrl = 'https://api.cursos.go2dev.cloud'
// const apiUrl = 'http://10.0.0.125:3333'

const api = axios.create({
  baseURL: apiUrl
})

export default api
